export const ROLES = {
  ADMINISTRATOR: 'Administrator',
  MEDICAL_CURATOR: 'MedicalCurator',
  POLIS_OPERATOR_DOP: 'polis.operator.dop',
  POLIS_OPERATOR: 'polis.operator',
  Only_Labs: 'Only Labs',
  DIS_MONITORING_MEDICAL_WORKER: 'medical.worker.dis.monitoring',
  DIS_MONITORING_ADMIN: 'Dis.monitoring.admin',
  DIS_MONITORING_TEAM: 'Dis.monitoring.team',
  OPERATOR: 'Operator',
  CLINIC_ADMINISTRATOR: 'ClinicAdministrator',
  ADMIN_DIS_MONITORING: 'admin.Dis.monitoring',
  DIS_MONITORING_OPERATOR: 'Dis.monitoring.operator',
  TECH_OPERATOR: 'Tech.operator',
  MEDICAL_CONCIERGE: 'Medical.concierge',
  QUALITY_SPECIALIST: 'Quality.Specialist',
  QUALITIES_OF_DOCTORS_HEAD: 'Qualities.of.doctors.head',
  QUALITIES_OF_DOCTORS: 'Qualities.of.doctors',
};

export default ROLES;
